<template>
    <div class="relative text-sm">
        <div v-if="label || $slots.label" class="mb-1 line-clamp-1 text-gray-700">
            <slot name="label">
                {{ label }}
            </slot>
            <span v-if="required" class="text-red-600 font-medium"> * </span>
        </div>

        <div
            class="bg-white border border-slate-300/70 rounded-md overflow-hidden relative w-full min-h-10 grid items-center"
            :class="[
                { '!border-sky-600': isFocus },
                { '!bg-red-50/70 !border-red-300/80': messages.length },
                { '!bg-slate-300/15 !border-slate-200/70': disabled },
                containerClass,
            ]"
        >
            <slot name="input-prepend" :input="input" :is-focus="isFocus" />
            <input
                ref="input"
                :type="type"
                :value="modelValue"
                :class="[inputClass, { 'pr-9 truncate': resetIcon && type !== 'number' }]"
                :style="inputStyle"
                :disabled="disabled"
                :readonly="readonly"
                class="bg-transparent inset-0 absolute w-full h-full px-3"
                :min="min"
                :max="max"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput"
                @change="handleChange"
            />

            <div v-if="!modelValue && value" class="text-gray-400 truncate px-3 select-none">{{ value }}</div>

            <div
                v-else-if="!modelValue && placeholder && modelValue !== 0"
                class="truncate px-3 select-none text-gray-400"
                :class="placeholderClass"
            >
                {{ placeholder }}
            </div>

            <button
                v-if="resetIcon && (value || modelValue) && !['number'].includes(type)"
                type="button"
                tabindex="-1"
                class="absolute right-2 top-1/2 -translate-y-1/2 rounded-full duration-200 hover:bg-slate-200/70 text-gray-500/60 hover:text-gray-500 p-1 aspect-square"
                @click="handleResetClick"
            >
                <Icon name="close" :size="18" />
            </button>

            <slot name="input-append" :input="input" :is-focus="isFocus" />
        </div>

        <div v-if="messages.length" class="text-xs text-left mt-1.5 text-red-600">
            {{ messages[0] }}
        </div>

        <slot name="wrapper-append" :input="input" :is-focus="isFocus" />
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: [Number, String, null],
        required: true,
        default: '',
    },

    name: {
        type: String,
        default: null,
    },

    value: {
        type: [String, null],
        default: '',
    },

    label: {
        type: [String, null],
        default: null,
    },

    type: {
        type: String,
        default: 'text',
    },

    required: {
        type: Boolean,
        default: false,
    },

    disabled: {
        type: Boolean,
        default: false,
    },

    readonly: {
        type: Boolean,
        default: false,
    },

    inputClass: {
        type: String,
        default: '',
    },

    placeholderClass: {
        type: String,
        default: '',
    },

    inputStyle: {
        type: [Object, String],
        default: null,
    },

    containerClass: {
        type: String,
        default: null,
    },

    placeholder: {
        type: String,
        default: '',
    },

    resetIcon: {
        type: Boolean,
        default: true,
    },

    min: {
        type: Number,
        default: null,
    },

    max: {
        type: Number,
        default: null,
    },
});

const isFocus = ref(false);
const input = ref(null);
const messages = ref([]);

const emit = defineEmits(['update:modelValue', 'reset', 'blur', 'focus']);

const fields = inject('fields', false);

if (fields) {
    fields.push({
        name: props.name,
        modelValue: computed(() => props.modelValue),
        messages: messages.value,
    });
}

function handleInput(event) {
    emit('update:modelValue', event.target.value);
    messages.value.length = 0;
}

function handleChange(event) {
    if (props.disabled || props.readonly) {
        return;
    }

    emit('update:modelValue', event.target.value);
    messages.value.length = 0;
}

function handleResetClick(event) {
    event.target.value = '';
    emit('update:modelValue', '');
    emit('reset', '');
}

function handleFocus(event) {
    isFocus.value = true;
    emit('focus', event);
}

function handleBlur(event) {
    isFocus.value = false;
    emit('blur', event);
}

defineExpose({
    messages,
    isFocus,
    element: input,
});
</script>
